/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import {
	LiftInterface,
	BankInterface,
	SocketMessageInterface,
	LayoutInterface,
} from "../../interfaces/EdgeManagement.interface";

export interface MonitoringState {
	openDrawerLiftActions: boolean;
	liftSelected: LiftInterface;
	bankSelected: BankInterface;
	layoutSelected: LayoutInterface;
	controlPanelTab: {
		liftType: string;
		tab: string;
	};
	controlPanelDrawerPlacement: "right" | "left";
	openModalControlPanel: boolean;
	openModalSaveGrid: boolean;
	openModalManageGrids: boolean;
	serverSelected: Object;
	enableDragAndDrop: boolean;
	showFullView: boolean;
	monitoringGridType: string;
	idGridSelected: string;
	cascaderSelectMultipleSelectionOptions: [];
	topViewLayout: [];
	detailViewLayout: [];
	statusViewLayout: [];
	serviceViewLayout: [];
	refreshPage: boolean;
	sendNewMessage: Object;
	showQueueInfo: boolean;
	clearQueue: boolean;
	openGlobalTerminal: boolean;
	totpIsValid: boolean;
	monitorViewIsLoading: boolean;
	socketConnection: string;
	edgeServerIsConnected: boolean;
	emulatorIsConnected: boolean;
	terminalIdLiftSelected: string;
	terminalResetTable: boolean;
	terminalShowJsonObject: boolean;
	terminalAutoScroll: boolean;
	terminalLastShortMessage: SocketMessageInterface;
	panelDefaultState: SocketMessageInterface;
	panelDefaultStateFaults: boolean;
	terminalShowAllMessages: boolean;
	terminalDownloadReport: boolean;
	elevatorSVGIcons: string[];
	faults: {
		liftId: string;
		type: string;
	};
	existsFaultsInMonitoringView: boolean;
	existsFaultsInLiftSelected: boolean;
	iconDefinitionElevator: any;
	iconDefinitionEscalator: any;
	iconDefinitionMovingwalk: any;
	favoriteGridName: string;
}

const initialState: MonitoringState = {
	openDrawerLiftActions: false,
	liftSelected: {
		id: "",
		bank_id: "",
		control_panel: {},
		direction: "",
		name: "",
		position: 1,
		type: "",
		lift_floors: [],
	},
	bankSelected: {
		id: "",
		active: false,
		board: 0,
		columns: 0,
		floor_below_lobby: 0,
		floor_count: 0,
		logical_port: 0,
		name: "",
		number_of_lifts: 0,
		channel_id: "",
		rear_doors: false,
		stops: 0,
		type: "",
		control_panel: {},
		bank_floors: [],
		lifts: [],
	},
	layoutSelected: {
		value: "",
		label: "",
		children: [],
		type: "",
		idOrganization: "",
		idServer: "",
		idChannel: "",
		idBank: "",
		nameOrganization: "",
		nameServer: "",
		nameChannel: "",
		typeChannel: "",
		id: "",
		active: false,
		board: 0,
		columns: 0,
		floor_below_lobby: 0,
		floor_count: 0,
		logical_port: 0,
		name: "",
		number_of_lifts: 0,
		channel_id: "",
		rear_doors: false,
		stops: 0,
		control_panel: {},
		bank_floors: [],
		lifts: [],
		floorsInUse: [],
		i: "",
		x: 0,
		y: 0,
		w: 0,
		h: 0,
		l: 0,
		f: 0,
		floor: 0,
	},
	controlPanelTab: {
		liftType: "ELEVATOR",
		tab: "FAULTS",
	},
	controlPanelDrawerPlacement: "right",
	openModalControlPanel: false,
	openModalSaveGrid: false,
	openModalManageGrids: false,
	serverSelected: [],
	enableDragAndDrop: false,
	showFullView: false,
	monitoringGridType: "FULLVIEW",
	idGridSelected: "",
	cascaderSelectMultipleSelectionOptions: [],
	topViewLayout: [],
	detailViewLayout: [],
	statusViewLayout: [],
	serviceViewLayout: [],
	refreshPage: false,
	sendNewMessage: {},
	showQueueInfo: false,
	clearQueue: false,
	openGlobalTerminal: false,
	totpIsValid: false,
	monitorViewIsLoading: false,
	socketConnection: process.env.REACT_APP_EDGE_SERVER_CONNECTION || "",
	edgeServerIsConnected: false,
	emulatorIsConnected: false,
	terminalIdLiftSelected: "",
	terminalResetTable: false,
	terminalShowJsonObject: false,
	terminalAutoScroll: true,
	terminalLastShortMessage: {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		bank_name: "",
		lift_name: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			down: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			up_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
			down_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
		},
		faults: false,
		position: 1,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
		direction: "",
	},
	panelDefaultState: {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		bank_name: "",
		lift_name: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			down: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			up_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
			down_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
		},
		faults: false,
		position: 1,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
		direction: "",
	},
	panelDefaultStateFaults: false,
	terminalShowAllMessages: false,
	terminalDownloadReport: false,
	elevatorSVGIcons: [
		"actionAlert",
		"actionClose",
		"actionLock",
		"actionOpen",
		"elDoubleIndicator",
		"elDoubleOutService",
		"elFrontCloseRearClose",
		"elFrontCloseRearOpen",
		"elFrontCloseRearOpening",
		"elFrontOpenRearClose",
		"elFrontOpenRearOpen",
		"elFrontOpeningRearClose",
		"elFrontOpeningRearOpening",
		"elClose",
		"elOpen",
		"elOpening",
		"elOutIndicator",
		"elOutService",
		"elDown",
		"elLocked",
		"elNotAllowed",
		"elUp",
		"elWarning",
		"elGoal",
		"elGoalHallCall",
	],
	faults: {
		liftId: "",
		type: "",
	},
	existsFaultsInMonitoringView: false,
	existsFaultsInLiftSelected: false,
	iconDefinitionElevator: {},
	iconDefinitionEscalator: {},
	iconDefinitionMovingwalk: {},
	favoriteGridName: "",
};

export const monitoringSlice = createSlice({
	name: "monitoring",
	initialState,
	reducers: {
		updateOpenDrawerLiftActions: (state, action: PayloadAction<boolean>) => {
			state.openDrawerLiftActions = action.payload;
		},
		updateLiftSelected: (state, action: PayloadAction<LiftInterface>) => {
			state.liftSelected = action.payload;
		},
		updateBankSelected: (state, action: PayloadAction<BankInterface>) => {
			state.bankSelected = action.payload;
		},
		updateLayoutSelected: (state, action: PayloadAction<LayoutInterface>) => {
			state.layoutSelected = action.payload;
		},
		updateModalControlPanelTab: (state, action: PayloadAction<any>) => {
			state.controlPanelTab = action.payload;
		},
		updateOpenModalControlPanel: (state, action: PayloadAction<boolean>) => {
			state.openModalControlPanel = action.payload;
		},
		updateOpenModalSaveGrid: (state, action: PayloadAction<boolean>) => {
			state.openModalSaveGrid = action.payload;
		},
		updateOpenModalManageGrids: (state, action: PayloadAction<boolean>) => {
			state.openModalManageGrids = action.payload;
		},
		updateServerSelected: (state, action: PayloadAction<Object>) => {
			state.serverSelected = action.payload;
		},
		updateEnableDragAndDrop: (state, action: PayloadAction<boolean>) => {
			state.enableDragAndDrop = action.payload;
		},
		updateShowFullView: (state, action: PayloadAction<boolean>) => {
			state.showFullView = action.payload;
		},
		updateMonitoringGridType: (state, action: PayloadAction<string>) => {
			state.monitoringGridType = action.payload;
		},
		updateIdGridSelected: (state, action: PayloadAction<string>) => {
			state.idGridSelected = action.payload;
		},
		updateCascaderSelectMultipleSelectionOptions: (state, action: PayloadAction<any>) => {
			state.cascaderSelectMultipleSelectionOptions = action.payload;
		},
		updateTopViewLayout: (state, action: PayloadAction<any>) => {
			state.topViewLayout = action.payload;
		},
		updateDetailViewLayout: (state, action: PayloadAction<any>) => {
			state.detailViewLayout = action.payload;
		},
		updateStatusViewLayout: (state, action: PayloadAction<any>) => {
			state.statusViewLayout = action.payload;
		},
		updateServiceViewLayout: (state, action: PayloadAction<any>) => {
			state.serviceViewLayout = action.payload;
		},
		updateRefreshPage: (state, action: PayloadAction<boolean>) => {
			state.refreshPage = action.payload;
		},
		updateSendNewMessage: (state, action: PayloadAction<Object>) => {
			state.sendNewMessage = action.payload;
		},
		updateShowQueueInfo: (state, action: PayloadAction<boolean>) => {
			state.showQueueInfo = action.payload;
		},
		updateClearQueue: (state, action: PayloadAction<boolean>) => {
			state.clearQueue = action.payload;
		},
		updateOpenGlobalTerminal: (state, action: PayloadAction<boolean>) => {
			state.openGlobalTerminal = action.payload;
		},
		updateSocketConnection: (state, action: PayloadAction<string>) => {
			state.socketConnection = action.payload;
		},
		updateTotpIsValid: (state, action: PayloadAction<boolean>) => {
			state.totpIsValid = action.payload;
		},
		updateMonitorViewIsLoading: (state, action: PayloadAction<boolean>) => {
			state.monitorViewIsLoading = action.payload;
		},
		updateEdgeServerIsConnected: (state, action: PayloadAction<boolean>) => {
			state.edgeServerIsConnected = action.payload;
		},
		updateEmulatorIsConnected: (state, action: PayloadAction<boolean>) => {
			state.emulatorIsConnected = action.payload;
		},
		updateTerminalIdLiftSelected: (state, action: PayloadAction<string>) => {
			state.terminalIdLiftSelected = action.payload;
		},
		updateTerminalResetTable: (state, action: PayloadAction<boolean>) => {
			state.terminalResetTable = action.payload;
		},
		updateTerminalShowJsonObject: (state, action: PayloadAction<boolean>) => {
			state.terminalShowJsonObject = action.payload;
		},
		updateTerminalAutoScroll: (state, action: PayloadAction<boolean>) => {
			state.terminalAutoScroll = action.payload;
		},
		updateTerminalLastShortMessage: (state, action: PayloadAction<SocketMessageInterface>) => {
			state.terminalLastShortMessage = action.payload;
		},
		updatePanelDefaultState: (state, action: PayloadAction<SocketMessageInterface>) => {
			state.panelDefaultState = action.payload;
		},
		updatePanelDefaultStateFaults: (state, action: PayloadAction<boolean>) => {
			state.panelDefaultStateFaults = action.payload;
		},
		updateTerminalShowAllMessages: (state, action: PayloadAction<boolean>) => {
			state.terminalShowAllMessages = action.payload;
		},
		updateTerminalDownloadReport: (state, action: PayloadAction<boolean>) => {
			state.terminalDownloadReport = action.payload;
		},
		updateFaults: (state, action: PayloadAction<any>) => {
			state.faults = action.payload;
		},
		updateExistsFaultsInMonitoringView: (state, action: PayloadAction<boolean>) => {
			state.existsFaultsInMonitoringView = action.payload;
		},
		updateExistsFaultsInLiftSelected: (state, action: PayloadAction<boolean>) => {
			state.existsFaultsInLiftSelected = action.payload;
		},
		updateIconDefinitionElevator: (state, action: PayloadAction<any>) => {
			state.iconDefinitionElevator = action.payload;
		},
		updateIconDefinitionEscalator: (state, action: PayloadAction<any>) => {
			state.iconDefinitionEscalator = action.payload;
		},
		updateIconDefinitionMovingwalk: (state, action: PayloadAction<any>) => {
			state.iconDefinitionMovingwalk = action.payload;
		},
		updateControlPanelDrawerPlacement: (state, action: PayloadAction<"right" | "left">) => {
			state.controlPanelDrawerPlacement = action.payload;
		},
		updateFavoriteGridName: (state, action: PayloadAction<string>) => {
			state.favoriteGridName = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateOpenDrawerLiftActions,
	updateLiftSelected,
	updateBankSelected,
	updateLayoutSelected,
	updateModalControlPanelTab,
	updateOpenModalControlPanel,
	updateOpenModalSaveGrid,
	updateOpenModalManageGrids,
	updatePanelDefaultState,
	updateServerSelected,
	updateEnableDragAndDrop,
	updateShowFullView,
	updateMonitoringGridType,
	updateIdGridSelected,
	updateCascaderSelectMultipleSelectionOptions,
	updateDetailViewLayout,
	updateTopViewLayout,
	updateStatusViewLayout,
	updateServiceViewLayout,
	updateRefreshPage,
	updateSendNewMessage,
	updateShowQueueInfo,
	updateClearQueue,
	updateSocketConnection,
	updateOpenGlobalTerminal,
	updateTotpIsValid,
	updateMonitorViewIsLoading,
	updateEdgeServerIsConnected,
	updateEmulatorIsConnected,
	updateTerminalIdLiftSelected,
	updateTerminalResetTable,
	updateTerminalShowJsonObject,
	updateTerminalAutoScroll,
	updateTerminalLastShortMessage,
	updateTerminalShowAllMessages,
	updateTerminalDownloadReport,
	updateFaults,
	updateExistsFaultsInMonitoringView,
	updateExistsFaultsInLiftSelected,
	updateIconDefinitionElevator,
	updateIconDefinitionEscalator,
	updateIconDefinitionMovingwalk,
	updatePanelDefaultStateFaults,
	updateControlPanelDrawerPlacement,
	updateFavoriteGridName,
} = monitoringSlice.actions;

export default monitoringSlice.reducer;
