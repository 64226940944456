import React, { useEffect, useState, useRef } from "react";
// ANT DESIGN COMPONENTS
import { Button, Popover, notification } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import {
	usePostMonitoringCommandMutation,
	usePostEmulatorCommandMutation,
} from "../../../redux/monitoring/monitoringAPI";
// AUTHENTICATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import ActionButtonPopover from "./ActionButtonPopover";
import GlobalAlert2 from "../../home/GlobalAlert2";
// INTERFACES
import { ActionButtonInterface } from "../../../interfaces/Actions.interface";
import { RootState } from "../../../app/store";

function ActionButtonToggle({ action, liftId, serverId, carService, layout, isControlPanel }: ActionButtonInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [localCarService, setLocalCarService] = useState<string>("");
	const [lastCommandSent, setLastCommandSent] = useState<string>("ON");
	const localCarServiceRef = useRef(localCarService);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { liftSelected, socketConnection, layoutSelected } = useSelector((state: RootState) => state.monitoring);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postCommand,
		{
			isLoading: isLoadingPostCommand,
			isSuccess: isSuccessPostCommand,
			isError: isErrorPostCommand,
			error: errorPostCommand,
			reset: resetPostCommand,
		},
	] = usePostMonitoringCommandMutation();
	const [
		postCommandEmulator,
		{
			isLoading: isLoadingPostEmulatorCommand,
			isSuccess: isSuccessPostEmulatorCommand,
			isError: isErrorPostEmulatorCommand,
			error: errorPostEmulatorCommand,
			reset: resetPostEmulatorCommand,
		},
	] = usePostEmulatorCommandMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickCommand = async (command: string, cmdOff: boolean, type: string, message: string) => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				cmd_adapter: isControlPanel ? layoutSelected.typeChannel === "ADAPTER" : layout.typeChannel === "ADAPTER",
				cmd_off: cmdOff,
				lift_id: liftId,
				short_msg: command,
				type: type || "CONTROL",
				message,
			},
			serverId,
		};
		postCommand(BODY);
	};

	const onClickCommandEmulator = async (command: any) => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				lift_id: isControlPanel ? liftSelected.id : liftId,
				bank_id: isControlPanel ? layoutSelected.id : layout.id,
				bankName: isControlPanel ? layoutSelected.name : layout.name,
				liftName: liftSelected.name,
				type: "COMMAND",
				command,
			},
			serverId,
		};
		postCommandEmulator(BODY);
	};

	const onClickToggleOn = () => {
		setLastCommandSent("ON");
		if (socketConnection === "EMULATOR") {
			onClickCommandEmulator(action && action.action && action.action.cmd ? action.action.cmd : "");
		} else {
			onClickCommand(
				action && action.action && action.action.cmd ? action.action.cmd : "",
				false,
				action && action.action && action.action.type ? action.action.type : "",
				action && action.action && action.action.label ? action.action.label : ""
			);
		}
	};

	const onClickToggleOff = () => {
		setLastCommandSent("OFF");
		if (socketConnection === "EMULATOR") {
			onClickCommandEmulator(
				action && action.toggle_action && action.toggle_action.cmd ? action.toggle_action.cmd : ""
			);
		} else {
			onClickCommand(
				action && action.toggle_action && action.toggle_action.cmd ? action.toggle_action.cmd : "",
				true,
				action && action.toggle_action && action.toggle_action.type ? action.toggle_action.type : "",
				action && action.toggle_action && action.toggle_action.label ? action.toggle_action.label : ""
			);
		}
	};

	const launchTimeoutMessage = () => {
		notification.open({
			message: <h5 className='generalStyles__warning'>TIMEOUT</h5>,
			description: <span>{action.action.label}</span>,
			placement: "top",
			className: `monitoring__notification__${theme}`,
			icon: (
				<FontAwesomeIcon
					icon={faCheckCircle}
					fontSize={18}
					className='monitoring__modalSettings__icon generalStyles__warning'
				/>
			),
		});
	};

	const launchSuccessMessage = (label: string) => {
		notification.open({
			message: <h5 className='generalStyles__success'>SUCCESS</h5>,
			description: <span>{label}</span>,
			placement: "top",
			className: `monitoring__notification__${theme}`,
			icon: (
				<FontAwesomeIcon
					icon={faCheckCircle}
					fontSize={18}
					className='monitoring__modalSettings__icon generalStyles__success'
				/>
			),
		});
	};

	const checkCarServiceState = () => {
		const currentLocalCarService = localCarServiceRef.current; // Get the latest value
		// Check the state of the variable
		if (
			lastCommandSent === "ON" &&
			action &&
			action.action &&
			action.action.valid_states.includes(currentLocalCarService)
		) {
			launchSuccessMessage(action.action.label || "--");
			return true;
		}
		if (
			lastCommandSent === "OFF" &&
			action &&
			action.toggle_action &&
			action.toggle_action.valid_states.includes(currentLocalCarService)
		) {
			launchSuccessMessage(action.toggle_action.label || "--");
			return true;
		}
		return false;
	};

	const checkVariableState = (maxAttempts = 5, interval = 1000) => {
		let attempts = 0;
		const intervalId = setInterval(() => {
			attempts += 1;
			if (checkCarServiceState()) {
				clearInterval(intervalId); // Stop checking
			} else if (attempts >= maxAttempts) {
				clearInterval(intervalId); // Stop checking
				launchTimeoutMessage();
			}
		}, interval);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		resetPostCommand();
		resetPostEmulatorCommand();
		if (isSuccessPostEmulatorCommand || isSuccessPostCommand) {
			checkVariableState();
		}
	}, [isSuccessPostEmulatorCommand, isSuccessPostCommand]);

	useEffect(() => {
		setLocalCarService(carService);
	}, [carService]);

	useEffect(() => {
		localCarServiceRef.current = localCarService;
	}, [localCarService]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100 generalStyles__flexCenter mb-4'>
			<div>
				<div className='generalStyles__flex'>
					<div style={{ width: "30px", paddingTop: "30px", marginLeft: "-15px" }}>
						<Popover
							content={
								<ActionButtonPopover
									action={action.action}
									toggleAction={action.toggle_action}
									timer={0}
									type='TOGGLE'
								/>
							}
							title={action && action.action && action.action.tooltip ? action.action.tooltip : "--"}
						>
							<FontAwesomeIcon
								icon={faCircleInfo}
								fontSize={18}
								className='generalStyles__success generalStyles__mrFix'
							/>
						</Popover>
					</div>
					<Button
						className={
							action && action.action && action.action.valid_states.includes(localCarService)
								? "buttonStyle__21"
								: "buttonStyle__22"
						}
						disabled={action && action.action && action.action.valid_states.includes(localCarService)}
						loading={(isLoadingPostCommand || isLoadingPostEmulatorCommand) && lastCommandSent === "ON"}
						onClick={onClickToggleOn}
						style={{ width: "210px" }}
					>
						<span className='generalStyles__textEllipsis' style={{ maxWidth: "100px" }}>
							{action && action.action && action.action.label ? `${action.action.label}` : "--"}
						</span>{" "}
						<span className='generalStyles__textEllipsis' style={{ marginLeft: "5px" }}>
							ON
						</span>
					</Button>
				</div>
				<div className='generalStyles__flex' style={{ marginTop: "-13px" }}>
					<div style={{ width: "15px" }} />
					<Button
						className={
							action && action.toggle_action && action.toggle_action.valid_states.includes(localCarService)
								? "buttonStyle__23"
								: "buttonStyle__24"
						}
						disabled={action && action.toggle_action && action.toggle_action.valid_states.includes(localCarService)}
						loading={(isLoadingPostCommand || isLoadingPostEmulatorCommand) && lastCommandSent === "OFF"}
						onClick={onClickToggleOff}
						style={{ width: "210px" }}
					>
						<span className='generalStyles__textEllipsis' style={{ maxWidth: "100px" }}>
							{action && action.toggle_action && action.toggle_action.label ? `${action.toggle_action.label}` : "--"}
						</span>
						<span className='generalStyles__textEllipsis' style={{ marginLeft: "5px" }}>
							OFF
						</span>
					</Button>
				</div>
			</div>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostCommand || isErrorPostEmulatorCommand}
				isSuccess={false}
				requestType='POST'
				error={errorPostCommand || errorPostEmulatorCommand}
				name='Command'
			/>
		</div>
	);
}

export default ActionButtonToggle;
